import { CAPTCHA_ACTION, CAPTCHA_KEY, CAPTCHA_FAIL_TOKEN } from 'consts';

const captchaManager = {
    getCaptchaToken() {
        return new Promise((resolve) => {
            const timer = setTimeout(() => {
                resolve(CAPTCHA_FAIL_TOKEN);
            }, 10000);

            try {
                grecaptcha.ready(() => {
                    grecaptcha.execute(CAPTCHA_KEY, { action: CAPTCHA_ACTION }).then((captchaToken) => {
                        clearTimeout(timer);
                        resolve(captchaToken);
                    });
                });
            } catch (error) {
                clearTimeout(timer);
                resolve(CAPTCHA_FAIL_TOKEN);
            }
        });
    },
    getYandexCaptchaToken() {
        return new Promise((resolve) => {
            try {
                if (!window.smartCaptcha) {
                    resolve();
                }

                window.smartCaptcha.reset();
                window.smartCaptcha.execute();

                window.smartCaptcha.subscribe(window.smartCaptcha.widgetId, 'success', (smartToken) => {
                    resolve(smartToken);
                });
            } catch (e) {
                resolve();
            }
        });
    },
};

export default captchaManager;
