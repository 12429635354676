import cn from 'classnames';
import React from 'react';

import styles from './Link.module.scss';

interface Props {
    className: string;
    onClick: () => void;
    children: React.ReactNode;
}

export function Link({ className, onClick, children }: Props) {
    return (
        <button className={cn(styles.root, className)} onClick={onClick}>
            {children}
        </button>
    );
}
