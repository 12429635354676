import React from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import url from 'url';
import { GlobalPreloader } from 'components/common';
import { Popover, POPOVER_DIRECTION } from 'components/common/popover';
import { taisUrlConverter as Query } from 'utils/converters';
import { getLink } from 'utils/redirect-manager';
import { cacheManager } from 'utils/cache-manager';
import { getBookingUrl, REDIRECT_ENABLED, ISO_DATE_MASK, DATE_MASK, SEARCH_DIRECTION_TYPES } from 'consts';
import { METRICS_EVENT_NAMES } from 'constants/metrics';
import { ReactComponent as ChangeDirection } from 'icons/interface/change-direction.svg';
import { fetchUserBenefits, mapUserBenefits, updateUserBenefits } from 'actions/available-benefits';
import { connect } from 'react-redux';

import { SEARCH_CACHE_NAME } from './actions';
import { WrappedCityFinder } from './CityFinder';
import { WrappedCalendars } from './Calendars';
import { WrappedPassengers } from './Passengers';
import { TicketSelector } from './TicketSelector';
import './SearchWidget.scss';
import { UTAIR_BENEFITS, DEFAULT_TICKET_TYPE, AIRPORT_TYPES } from './const';
import { ChooseAirportModal } from './ChooseAirportModal';
import {
    findAvailableAirportTypes,
    getIsSameCity,
    validateFieldText,
    getTooltipTextFieldFrom,
    getTooltipTextFieldDestination,
} from './utils';

class SearchWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPreloderShown: false,
            hasFromCityError: false,
            hasDestCityError: false,
            hasSameCityError: false,
            hasIncorrectTextFieldFrom: false,
            hasIncorrectTextFieldDestination: false,
            hasDateError: false,
            ticketTypeError: false,
            hasPassengersError: false,
            isNotFoundFromCity: false,
            isNotFoundDestinationCity: false,
            isDropdownOpen: false,
            isFocusedPassengers: false,
            isShowChooseAirportModal: false,
        };

        this.buttonStyle = {
            width: '1.5em',
            height: '1.5em',
        };
    }

    componentDidMount() {
        const { pushMetricsEvent, isUserCanBuyStandby, availableBenefits } = this.props;
        pushMetricsEvent(METRICS_EVENT_NAMES.SEARCH_FORM_OPENED);

        if (isUserCanBuyStandby && !availableBenefits.length) {
            this.fetchBenefits();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            fromCity,
            destCity,
            fromDate,
            backDate,
            isTwoWay,
            isAvailableFromDatesLoaded,
            isAvailableBackDatesLoaded,
            isUserCanBuyStandby,
        } = this.props;

        if (prevProps.isUserCanBuyStandby !== isUserCanBuyStandby && isUserCanBuyStandby) {
            this.fetchBenefits();
        }

        if (
            !this.calendar?.state?.isShowCalendar &&
            fromCity &&
            destCity &&
            isAvailableFromDatesLoaded &&
            isAvailableBackDatesLoaded &&
            (isTwoWay ? !(fromDate && backDate) : !fromDate)
        ) {
            if (
                fromDate !== prevProps.fromDate ||
                backDate !== prevProps.backDate ||
                isTwoWay !== prevProps.isTwoWay ||
                isAvailableFromDatesLoaded !== prevProps.isAvailableFromDatesLoaded ||
                isAvailableBackDatesLoaded !== prevProps.isAvailableBackDatesLoaded
            ) {
                this.calendar.focus();
            }
        }
    }

    get isStandbyTicketType() {
        const { ticketType } = this.props;

        return UTAIR_BENEFITS[ticketType] === UTAIR_BENEFITS.UTAIR_STANDBY;
    }

    get isUtairBenefitsTicketType() {
        const { ticketType } = this.props;

        return !!UTAIR_BENEFITS[ticketType] && !this.isStandbyTicketType;
    }

    get isVacationTicketType() {
        const { ticketType } = this.props;

        return UTAIR_BENEFITS[ticketType] === UTAIR_BENEFITS.UTAIR_VACATION;
    }

    fetchBenefits = () => {
        const { updateBenefits } = this.props;
        fetchUserBenefits()
            .then((data) => updateBenefits(mapUserBenefits(data)))
            .catch((e) => console.error(e));
    };

    setCityFinderTo = (ref) => {
        this.cityFinderTo = ref;
    };

    setCalendar = (ref) => {
        this.calendar = ref;
    };

    setPassengersField = (ref) => {
        this.passengers = ref;
    };

    setTicketSelectorField = (ref) => {
        this.ticket = ref;
    };

    getAvailableTicketsTypes = () => {
        const { availableBenefits } = this.props;

        const availableTicketsTypes = [DEFAULT_TICKET_TYPE];

        if (!availableBenefits || availableBenefits.length === 0) {
            return availableTicketsTypes;
        }

        availableTicketsTypes.push(...availableBenefits);

        return availableTicketsTypes;
    };

    checkAirportType = () => {
        const { fromCity, destCity } = this.props;

        const availableAirportTypesFrom = findAvailableAirportTypes(fromCity.airports);
        const availableAirportTypesDest = findAvailableAirportTypes(destCity.airports);

        const availableAirportTypes = availableAirportTypesFrom.filter((type) =>
            availableAirportTypesDest.includes(type)
        );

        const hasHelicoperAirport = availableAirportTypes.includes(AIRPORT_TYPES.HELICOPTER);
        const hasAirplaneAirport = availableAirportTypes.includes(AIRPORT_TYPES.AIRPLANE);

        if (hasHelicoperAirport && hasAirplaneAirport) {
            this.setState({ isShowChooseAirportModal: true });
        } else {
            this.changeRouteParams(hasHelicoperAirport);
        }
    };

    handleStartSearch = (e) => {
        e.preventDefault();

        const {
            pushMetricsEvent,
            fromCity,
            destCity,
            fromDate,
            backDate,
            isTwoWay,
            passengers,
            ticketType,
            isUserCanBuyStandby,
            isCheckedUnaccompaniedMinor,
        } = this.props;
        const {
            hasIncorrectTextFieldFrom,
            hasIncorrectTextFieldDestination,
            isNotFoundFromCity,
            isNotFoundDestinationCity,
        } = this.state;
        const isValidCities = this.validateCities(fromCity, destCity);
        const isValidFromDate = this.validateFromDate(fromDate);
        const isValidTicketType = isUserCanBuyStandby ? this.validateTicketType(ticketType) : true;
        const isValidPassengers = this.validatePassengers(passengers);
        const isValidCitiesText = !hasIncorrectTextFieldDestination && !hasIncorrectTextFieldFrom;
        const isFoundCities = !isNotFoundFromCity && !isNotFoundDestinationCity;

        if (
            isValidCities &&
            isValidFromDate &&
            isValidTicketType &&
            isValidPassengers &&
            isValidCitiesText &&
            isFoundCities
        ) {
            cacheManager.setItem(SEARCH_CACHE_NAME, {
                fromCity,
                destCity,
                isTwoWay,
                fromDate: fromDate ? fromDate.format(DATE_MASK) : '',
                backDate: backDate ? backDate.format(DATE_MASK) : '',
                passengers,
                ticketType,
                isCheckedUnaccompaniedMinor,
            });

            this.checkAirportType();

            pushMetricsEvent(METRICS_EVENT_NAMES.FIND_BUTTON_CLICKED, {
                from: fromCity.name,
                to: destCity.name,
                dep_date: fromDate?.format(ISO_DATE_MASK),
                back_date: backDate ? backDate.format(ISO_DATE_MASK) : '',
                adt_count: passengers.adultsCount,
                chd_count: passengers.childrenCount,
                inf_count: passengers.infantsCount,
            });
        }
    };

    validateCities = (fromCity, destCity) => {
        const hasSameCityError = getIsSameCity(fromCity, destCity);
        const hasFromCityError = hasSameCityError || !fromCity;
        const hasDestCityError = hasSameCityError || !destCity;

        this.setState({
            hasSameCityError,
            hasFromCityError,
            hasDestCityError,
        });

        return !(hasSameCityError || hasFromCityError || hasDestCityError);
    };

    updateHasIncorrectTextError = (value, direction) => {
        const hasError = validateFieldText(value);

        if (direction === SEARCH_DIRECTION_TYPES.FROM) {
            this.setState({
                hasIncorrectTextFieldFrom: hasError,
            });
        }

        if (direction === SEARCH_DIRECTION_TYPES.DESTINATION) {
            this.setState({
                hasIncorrectTextFieldDestination: hasError,
            });
        }
    };

    updateIsNotFoundCityError = (value, direction) => {
        const { fromCity, destCity } = this.props;

        if (direction === SEARCH_DIRECTION_TYPES.FROM && fromCity) {
            this.setState({
                isNotFoundFromCity: value !== fromCity.name,
            });
        }

        if (direction === SEARCH_DIRECTION_TYPES.DESTINATION && destCity) {
            this.setState({
                isNotFoundDestinationCity: value !== destCity.name,
            });
        }
    };

    gotToRoute = (sourceUrl) => {
        const { ticketType } = this.props;

        if (!REDIRECT_ENABLED || UTAIR_BENEFITS[ticketType]) {
            window.location.href = sourceUrl;

            return;
        }

        getLink(sourceUrl).then((redirectUrl) => {
            window.location.href = redirectUrl;
        });
    };

    handleInputCityFrom = (value) => {
        const { fetchCitiesFrom } = this.props;
        const { hasIncorrectTextFieldFrom } = this.state;

        this.updateHasIncorrectTextError(value, SEARCH_DIRECTION_TYPES.FROM);

        this.updateIsNotFoundCityError(value, SEARCH_DIRECTION_TYPES.FROM);

        if (!hasIncorrectTextFieldFrom) {
            fetchCitiesFrom(value);
        }
    };

    handleChangeCityFrom = (city) => {
        const { setFromCity, pushMetricsEvent, destCity } = this.props;
        setFromCity(city);

        if (city !== null) {
            this.cityFinderTo.focus();
            pushMetricsEvent(METRICS_EVENT_NAMES.DEP_POINT_CHOSEN, {
                from: city.name,
            });
            this.updateHasIncorrectTextError(city?.name, SEARCH_DIRECTION_TYPES.FROM);
            this.setState({
                isNotFoundFromCity: false,
            });
        }

        this.validateCities(city, destCity);
    };

    handleInputCityTo = (value) => {
        const { fetchCitiesTo } = this.props;
        const { hasIncorrectTextFieldFrom } = this.state;

        this.updateHasIncorrectTextError(value, SEARCH_DIRECTION_TYPES.DESTINATION);

        this.updateIsNotFoundCityError(value, SEARCH_DIRECTION_TYPES.DESTINATION);

        if (!hasIncorrectTextFieldFrom) {
            fetchCitiesTo(value);
        }
    };

    handleChangeCityTo = (city) => {
        const { setDestCity, pushMetricsEvent, fromCity } = this.props;
        setDestCity(city);

        if (city !== null) {
            this.calendar.focus();
            pushMetricsEvent(METRICS_EVENT_NAMES.ARR_POINT_CHOSEN, {
                to: city.name,
            });
            this.updateHasIncorrectTextError(city?.name, SEARCH_DIRECTION_TYPES.DESTINATION);
            this.setState({
                isNotFoundDestinationCity: false,
            });
        }

        this.validateCities(fromCity, city);
    };

    handleSwapCityFields = () => {
        // eslint-disable-next-line no-shadow
        const { swapCities } = this.props;
        swapCities();
    };

    handleToggleIsTwoWay = () => {
        const { isTwoWay, toggleIsTwoWay, pushMetricsEvent } = this.props;
        toggleIsTwoWay();
        pushMetricsEvent(METRICS_EVENT_NAMES.ONE_WAY_CHOSEN, {
            direction: !isTwoWay ? 'round_trip' : 'one_way',
        });
    };

    handleChangeDate = (fromDate, backDate) => {
        const { setFromDate, setBackDate, pushMetricsEvent, isTwoWay, isUserCanBuyStandby } = this.props;
        setFromDate(fromDate);
        setBackDate(backDate);

        if (fromDate) {
            pushMetricsEvent(METRICS_EVENT_NAMES.DEP_DATE_CHOSEN, {
                dep_date: fromDate.format(ISO_DATE_MASK),
            });
        }

        if (backDate) {
            pushMetricsEvent(METRICS_EVENT_NAMES.BACK_DATE_CHOSEN, {
                back_date: backDate.format(ISO_DATE_MASK),
            });
        }

        if (!isTwoWay || backDate) {
            this.validateFromDate(fromDate);

            if (isUserCanBuyStandby) {
                this.ticket?.focus();
            } else {
                this.passengers?.focus();
            }
        }
    };

    validateFromDate = (fromDate) => {
        const hasDateError = fromDate === null;
        this.setState({ hasDateError });

        return !hasDateError;
    };

    validateTicketType = (ticketType) => {
        const isEmptyTicketTyipeField = ticketType === null;
        this.setState({ ticketTypeError: isEmptyTicketTyipeField });

        return !isEmptyTicketTyipeField;
    };

    validatePassengers = (passengers) => {
        const hasPassengersError = !Object.values(passengers).some((count) => count >= 1);
        this.setState({ hasPassengersError });

        return !hasPassengersError;
    };

    handleChangePassengers = (passengers) => {
        const {
            passengers: { adultsCount, childrenCount, infantsCount },
            setPassengers,
            pushMetricsEvent,
        } = this.props;
        setPassengers(passengers);

        if (passengers.adultsCount !== adultsCount) {
            pushMetricsEvent(METRICS_EVENT_NAMES.ADT_COUNT_CHOSEN, {
                adt_count: passengers.adultsCount,
            });
        }

        if (passengers.childrenCount !== childrenCount) {
            pushMetricsEvent(METRICS_EVENT_NAMES.CHD_COUNT_CHOSEN, {
                chd_count: passengers.childrenCount,
            });
        }

        if (passengers.infantsCount !== infantsCount) {
            pushMetricsEvent(METRICS_EVENT_NAMES.INF_COUNT_CHOSEN, {
                inf_count: passengers.infantsCount,
            });
        }

        this.validatePassengers(passengers);
    };

    handleChangePassengersByChecked = (isChecked) => {
        const { onToggleUnaccompaniedMinor } = this.props;
        onToggleUnaccompaniedMinor();

        if (isChecked) {
            this.handleChangePassengers({ adultsCount: 0, childrenCount: 0, infantsCount: 0 });
        } else {
            this.handleChangePassengers({ adultsCount: 1, childrenCount: 0, infantsCount: 0 });
        }
    };

    toggleFocusedPassengers = (value) => {
        this.setState({ isFocusedPassengers: value });
    };

    handleOpenDropdown = () => {
        this.setState({ isDropdownOpen: true });
    };

    handleCloseDropdown = () => {
        this.setState({ isDropdownOpen: false });
    };

    handleChangeTicketType = (type) => {
        const { ticketType, setTicketType, setPassengers } = this.props;

        if (ticketType !== type) {
            setPassengers({
                employeesCount: DEFAULT_TICKET_TYPE === type ? 0 : 1,
                adultsCount: DEFAULT_TICKET_TYPE === type ? 1 : 0,
                childrenCount: 0,
                infantsCount: 0,
            });
        }

        setTicketType(type);
        this.passengers?.focus();
    };

    clearTicketType = () => {
        this.handleChangeTicketType(DEFAULT_TICKET_TYPE);

        const cache = cacheManager.getItem(SEARCH_CACHE_NAME) || {};

        cacheManager.setItem(SEARCH_CACHE_NAME, {
            ...cache,
            ticketType: DEFAULT_TICKET_TYPE,
            passengers: { adultsCount: 1, childrenCount: 0, infantsCount: 0 },
        });
    };

    changeRouteParams = (isHelicopter = false) => {
        const {
            passengers,
            fromDate,
            backDate,
            fromCity,
            destCity,
            lng,
            utmCampaign,
            utmSource,
            utmMedium,
            utmTerm,
            utmContent,
            isCheckedUnaccompaniedMinor,
            ticketType,
        } = this.props;

        this.setState({ isPreloderShown: true });

        if (!fromCity || !destCity) {
            window.location.href = getBookingUrl();

            return;
        }

        const params = Query.to({
            departure: fromCity.code,
            arrival: destCity.code,
            adult_count: passengers.adultsCount,
            child_count: passengers.childrenCount,
            infant_count: passengers.infantsCount,
            from_date: fromDate.valueOf(),
            back_date: backDate ? backDate.valueOf() : '',
        });

        const additionalParams = _.pickBy(
            {
                utm_source: utmSource,
                utm_medium: utmMedium,
                utm_campaign: utmCampaign,
                utm_term: utmTerm,
                utm_content: utmContent,
            },
            _.identity
        ); // params without empty values

        if (this.isStandbyTicketType) {
            additionalParams.flow = 'async';
            additionalParams.sirena_standby = 'utair';
        }

        if (this.isUtairBenefitsTicketType) {
            additionalParams.flow = 'async';
            additionalParams.type_of_benefit = ticketType.toLowerCase();
        }

        if (passengers.employeesCount) {
            additionalParams.employee_passenger = 1;
        }

        if (this.isVacationTicketType) {
            additionalParams.teenagers = passengers.teenagersCount;
        }

        if (isCheckedUnaccompaniedMinor && !this.isStandbyTicketType && !this.isUtairBenefitsTicketType) {
            additionalParams.flow = 'async';
            additionalParams.unaccompanied_minor = true;
        }

        if (isHelicopter) {
            additionalParams.is_helicopter = true;
        }

        const queryParams = _.isEmpty(additionalParams) ? null : url.format({ query: additionalParams });
        const targetUrl = getBookingUrl(params.substr(1), queryParams, lng);
        this.gotToRoute(targetUrl);
    };

    render() {
        const {
            isPreloderShown,
            hasSameCityError,
            hasDestCityError,
            hasFromCityError,
            isDropdownOpen,
            hasDateError,
            ticketTypeError,
            isFocusedPassengers,
            hasPassengersError,
            isShowChooseAirportModal,
            hasIncorrectTextFieldFrom,
            hasIncorrectTextFieldDestination,
            isNotFoundFromCity,
            isNotFoundDestinationCity,
        } = this.state;
        const {
            fromCity,
            destCity,
            citiesFrom,
            citiesTo,
            citiesPopular,
            defaultCitiesTo,
            passengers,
            fromDate,
            backDate,
            isTwoWay,
            availableFromDates,
            availableBackDates,
            isAvailableFromDatesLoaded,
            isAvailableBackDatesLoaded,
            lng,
            media,
            isUserCanBuyStandby,
            ticketType,
            isCheckedUnaccompaniedMinor,
        } = this.props;
        const isShowOnlyAvailableDates =
            (isTwoWay ? isAvailableFromDatesLoaded && isAvailableBackDatesLoaded : isAvailableFromDatesLoaded) &&
            fromCity !== null &&
            destCity !== null;
        const tooltipTextFieldFrom = getTooltipTextFieldFrom(hasIncorrectTextFieldFrom, isNotFoundFromCity, fromCity);
        const tooltipTextFieldDestination = getTooltipTextFieldDestination(
            hasSameCityError,
            hasIncorrectTextFieldDestination,
            isNotFoundDestinationCity,
            destCity
        );

        const shouldShowTooltipFromField = !isDropdownOpen && tooltipTextFieldFrom;
        const shouldShowTooltipDestinationField = !isDropdownOpen && tooltipTextFieldDestination;

        const isTooltipPassengersShow = !isFocusedPassengers && hasPassengersError;

        return (
            <div className="container-wide">
                <div className={`SearchWidget ${isUserCanBuyStandby ? 'Standby' : ''}`} data-testid="SearchWidget">
                    <Popover
                        defaultDirection={POPOVER_DIRECTION.BOTTOM}
                        tooltip={tooltipTextFieldFrom}
                        isShown={shouldShowTooltipFromField}
                        disableHover
                    >
                        <WrappedCityFinder
                            placeholder={t('flights_search.from')}
                            directionClassName="-from"
                            selectedCity={fromCity}
                            cities={citiesFrom}
                            defaultCities={citiesPopular}
                            error={hasFromCityError || hasIncorrectTextFieldFrom || isNotFoundFromCity}
                            onChange={this.handleChangeCityFrom}
                            onInput={this.handleInputCityFrom}
                            onOpenDropdown={this.handleOpenDropdown}
                            onCloseDropdown={this.handleCloseDropdown}
                            testid="SearchWidgetDepartureInput"
                            hasIncorrectTextError={hasIncorrectTextFieldFrom}
                        />
                    </Popover>

                    <button
                        disabled={!(fromCity || destCity)}
                        type="button"
                        className="SearchWidget-swapPlace"
                        tabIndex="-1"
                        onClick={this.handleSwapCityFields}
                        aria-label="Сменить города"
                    >
                        <ChangeDirection style={this.buttonStyle} />
                    </button>

                    <Popover
                        defaultDirection={POPOVER_DIRECTION.BOTTOM}
                        tooltip={tooltipTextFieldDestination}
                        isShown={shouldShowTooltipDestinationField}
                        disableHover
                    >
                        <WrappedCityFinder
                            placeholder={t('flights_search.to')}
                            directionClassName="-to"
                            selectedCity={destCity}
                            cities={!hasIncorrectTextFieldDestination ? citiesTo : []}
                            defaultCities={defaultCitiesTo.length ? defaultCitiesTo : citiesPopular}
                            error={hasDestCityError || hasIncorrectTextFieldDestination || isNotFoundDestinationCity}
                            onRef={this.setCityFinderTo}
                            onChange={this.handleChangeCityTo}
                            onInput={this.handleInputCityTo}
                            onOpenDropdown={this.handleOpenDropdown}
                            onCloseDropdown={this.handleCloseDropdown}
                            testid="SearchWidgetArrivalInput"
                            incorrectTextError={hasIncorrectTextFieldDestination}
                        />
                    </Popover>

                    <WrappedCalendars
                        onRef={this.setCalendar}
                        fromDate={fromDate}
                        backDate={backDate}
                        availableFromDates={availableFromDates}
                        availableBackDates={availableBackDates}
                        isError={hasDateError}
                        isTwoWay={isTwoWay}
                        isShowOnlyAvailableDates={isShowOnlyAvailableDates}
                        onToggleIsTwoWay={this.handleToggleIsTwoWay}
                        onChange={this.handleChangeDate}
                        lng={lng}
                        media={media}
                    />

                    {isUserCanBuyStandby && (
                        <TicketSelector
                            tickets={this.getAvailableTicketsTypes()}
                            activeTicketType={ticketType}
                            isError={ticketTypeError}
                            setActiveTicketType={this.handleChangeTicketType}
                            onRef={this.setTicketSelectorField}
                            data-testid="SearchWidgetTicketSelector"
                        />
                    )}

                    <Popover
                        defaultDirection={POPOVER_DIRECTION.BOTTOM}
                        tooltip="Добавьте пассажиров"
                        isShown={isTooltipPassengersShow}
                        disableHover
                    >
                        <WrappedPassengers
                            isStandbyTicketType={this.isStandbyTicketType}
                            isVacationTicketType={this.isVacationTicketType}
                            isCheckedUnaccompaniedMinor={isCheckedUnaccompaniedMinor}
                            activeTicketType={ticketType}
                            passengers={passengers}
                            setPassengers={this.handleChangePassengers}
                            onCheckedUnaccompaniedMinor={this.handleChangePassengersByChecked}
                            toggleFocusedPassengers={this.toggleFocusedPassengers}
                            onRef={this.setPassengersField}
                            error={hasPassengersError}
                        />
                    </Popover>

                    <div className="SearchWidget-box -button">
                        <button
                            onClick={this.handleStartSearch}
                            className="Button -grayLight SearchWidget-button"
                            type="button"
                            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                            tabIndex="1"
                            data-testid="SearchWidgetFindButton"
                        >
                            {t('common.actions.find')}
                        </button>
                    </div>
                </div>

                {isPreloderShown && <GlobalPreloader isWhite />}
                {isShowChooseAirportModal && (
                    <ChooseAirportModal
                        onButtonClick={this.changeRouteParams}
                        titleText={`${fromCity.name} — ${destCity.name}`}
                        onClose={() => {
                            this.setState({ isShowChooseAirportModal: false });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateBenefits: (data) => dispatch(updateUserBenefits(data)),
});

export default connect(null, mapDispatchToProps)(SearchWidget);
