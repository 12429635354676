import cn from 'classnames';
import React from 'react';

import type { Passenger } from '../../types';
import { PassengerInfo } from '../PassengerInfo';
import styles from './Row.module.scss';

interface Props {
    className: string;
    segment: {
        duration: string;
        date: string;
        gate: string;
    };
    passenger: Passenger;
    Action: React.ReactNode;
}

export function TabContentRow({ className, segment, passenger, Action }: Props) {
    return (
        <div className={cn(styles.root, className)}>
            <PassengerInfo className={styles.passenger} passenger={passenger} />
            <span className={styles.date}>{segment.date}</span>
            <span className={styles.duration}>{segment.duration}</span>
            <span className={styles.gate}>{segment.gate}</span>
            <span className={styles.seat}>{passenger.seat}</span>
            <div className={styles.action}>{Action}</div>
        </div>
    );
}
