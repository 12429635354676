/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { socialAuth, reset, socialRegistration, setDialogState } from './social/actions';
import { setGDPRAgreementDate } from 'actions/sign';
import { getSocialData, getMergingSocialData, getError, getDialogIsVisibleState } from './social/selectors';
import { getGDPRAgreementDate } from 'selectors/sign';
import { ReactComponent as Yandex } from './social-yandex.svg';
import { Agreement } from './Agreement';
import styles from './Social.module.scss';

const Dialog = ({ registrationOnClick, isAgree, onAgreementChange }) => (
    <Fragment>
        <div>
            <button className="Button -long !mt12" disabled={!isAgree} onClick={registrationOnClick}>
                {i18next.t('common.actions.sign_up')}
            </button>
        </div>

        <div className={styles.socailAgreementWrapper}>
            <Agreement checked={isAgree} onChange={() => onAgreementChange()} />
        </div>
    </Fragment>
);

class SocialAuth extends React.Component {
    componentWillUnmount() {
        this.props.reset();
    }

    buttonClick = (type) => {
        this.props.setIsLoading(true);
        this.props.socialAuth(type, this.socialAuthSuccessCallback, this.props.setIsLoading);
    };

    socialAuthSuccessCallback = () => {
        this.props.onSuccess();
    };

    registrationClickHandler = (event) => {
        event.preventDefault();
        this.props.setIsLoading(true);
        this.props.socialRegistration(this.socialAuthSuccessCallback, this.props.setIsLoading);
    };

    onAgreementChange = () => {
        const currentDate = Math.floor(Date.now() / 1000);
        const newDate = this.props.GDPRAgreementDate ? 0 : currentDate;

        this.props.setGDPRAgreementDate(newDate);
    };

    render() {
        const { error, isDialogVisible, isBusy, mergingSocialData, socialData } = this.props;
        let network = null;
        if (mergingSocialData) {
            network = mergingSocialData.network;
        }
        if (socialData) {
            network = socialData.network;
        }

        return (
            <Fragment>
                {!isDialogVisible && (
                    <>
                        <div className={styles.socialHeader}>
                            <span>или</span>
                        </div>
                        <div className="!mt24">
                            <button
                                type="button"
                                className={styles.socialButton}
                                onClick={() => this.buttonClick('yandex')}
                                disabled={network === 'yandex'}
                            >
                                <Yandex /> {i18next.t('sign.social.yandex')}
                            </button>
                        </div>
                    </>
                )}

                {isDialogVisible && (
                    <Dialog
                        isBusy={isBusy}
                        registrationOnClick={this.registrationClickHandler}
                        isAgree={this.props.GDPRAgreementDate}
                        onAgreementChange={() => this.onAgreementChange()}
                    />
                )}

                {error && <p className="mt-20 mb-20 fs-12 text-center color-red">{error}</p>}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    error: getError(state),
    isDialogVisible: getDialogIsVisibleState(state),
    socialData: getSocialData(state),
    GDPRAgreementDate: getGDPRAgreementDate(state),
    mergingSocialData: getMergingSocialData(state),
});

const mapDispatchToProps = (dispatch) => ({
    socialAuth: (type, callback, setIsLoading, data) => dispatch(socialAuth(type, callback, setIsLoading, data)),
    reset: (type) => dispatch(reset(type)),
    socialRegistration: (callback, setIsLoading) => dispatch(socialRegistration(callback, setIsLoading)),
    setGDPRAgreementDate: (timestamp) => dispatch(setGDPRAgreementDate(timestamp)),
    setDialogState: (isDialog) => dispatch(setDialogState(isDialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialAuth);
