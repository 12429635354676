/* eslint-disable no-underscore-dangle */
import i18next from 'i18next';
import store from 'store';
import Url from 'url';
import captchaManager from 'utils/captcha-manager';
import { cachedFetchWrapper as fetch, parseBody, parseError } from 'utils/fetch';
import { cacheManager } from 'utils/cache-manager';
import { ERROR_TYPES } from 'constants/errorTypes';
import { CLIENT_SESSION_CACHE_NAME, getClientAccessToken, getUserAccessToken, logout } from 'actions/auth';
import { getRockstatUid } from 'utils/rockstat-metrics';

import { forbiddenErrorHandler } from './forbidden-error-handler';

/**
 * cache settings for Get-request
 * @typedef {Object} CacheSettings
 * @property {string} [name]
 * @property {number} [lifeTime]
 * @property {string} [flag]
 */

/**
 * default cache life time - 1 min
 */
const DEFAULT_CACHE_LIFE_TIME = 60000;

const requestControllers = {};

/**
 * HTTP manager for Utair server
 * uses fetch
 * @singleton
 */
export const utairHttpManager = {
    /**
     * get source
     * @param {string} url
     * @param {Object} jsonQuery - wrapped query in json
     * @param {boolean} [isUserAuth] - true if need access token of user
     * @param {CacheSettings} [cacheSettings]
     * @param {boolean} forced - ignore data from cache
     * @param {string} requestId - id of group cancelable requests
     * @param {object} query - url query params
     * @param {boolean} withCaptcha - true if need to add google recaptcha header
     * @return {Promise}
     */
    get(...args) {
        let config;

        if (typeof args[0] === 'string') {
            config = {
                url: args[0],
                jsonQuery: args[1],
                isUserAuth: args[2],
                cacheSettings: args[3],
                forced: args[4],
                requestId: args[5],
                query: args[6],
                withCaptcha: args[7],
            };
        } else {
            [config] = args;
        }

        const makeRequest = () =>
            this._request(
                'GET',
                config.url,
                config.jsonQuery,
                null,
                config.isUserAuth,
                config.requestId,
                config.query,
                config.withCaptcha
            );

        if (config.cacheSettings instanceof Object) {
            const cacheName = config.cacheSettings.name || `${config.url}:${JSON.stringify(config.jsonQuery)}`;
            const cacheFlag = config.cacheSettings.flag;
            const cacheLifeTime = config.cacheSettings.lifeTime || DEFAULT_CACHE_LIFE_TIME;
            const cachedData = cacheManager.getItem(cacheName);

            if (!config.forced && cachedData) {
                return Promise.resolve(cachedData);
            }

            return makeRequest().then((data) => {
                cacheManager.setItem(cacheName, data, cacheLifeTime, cacheFlag);

                return data;
            });
        }

        return makeRequest();
    },

    /**
     * post new source
     * @param {string} url
     * @param {Object} data - body-params
     * @param {boolean} [isUserAuth] - true if need access token of user
     * @param {boolean} withCaptcha - true if need to add google recaptcha header
     * @return {Promise}
     */
    post(url, data, isUserAuth, withCaptcha) {
        return this._request('POST', url, null, data, isUserAuth, undefined, undefined, withCaptcha);
    },

    /**
     * update source
     * @param {string} url
     * @param {Object} data - body-params
     * @param {boolean} [isUserAuth] - true if need access token of user
     * @param {boolean} withCaptcha - true if need to add google recaptcha header
     * @return {Promise}
     */
    put(url, data, isUserAuth, withCaptcha) {
        return this._request('PUT', url, null, data, isUserAuth, undefined, undefined, withCaptcha);
    },

    /**
     * delete source
     * @param {string} url
     * @param {Object} data - get-params
     * @param {boolean} [isUserAuth] - true if need access token of user
     * @param {boolean} withCaptcha - true if need to add google recaptcha header
     * @return {Promise}
     */
    delete(url, data, isUserAuth, withCaptcha) {
        return this._request('DELETE', url, data, null, isUserAuth, undefined, undefined, withCaptcha);
    },

    /**
     * - get access token
     * - sign data
     * - create HTTP request
     * @param {string} method - GET, POST, PUT, DELETE
     * @param {string} url - base url
     * @param {Object} params - search-params
     * @param {Object} data - body-params
     * @param {boolean} isUserAuth - true if need access token of user
     * @param {string} requestId - id of group cancelable requests
     * @param {object} unwrappedParams - query params avoiding wrapping
     * @param {boolean} withCaptcha - true if need to add google recaptcha header
     * @return {Promise}
     * @private
     */
    _request(method, url, params, data, isUserAuth, requestId = '', unwrappedParams, withCaptcha) {
        const headers = new Headers();
        const getTokenAction = isUserAuth ? getUserAccessToken : getClientAccessToken;
        let body = null;
        let fullUrl = url;

        headers.append('Accept-Language', i18next.language);

        if (['GET', 'DELETE'].includes(method) && (params || unwrappedParams)) {
            const query = {
                json: JSON.stringify(params),
                ...unwrappedParams,
            };

            fullUrl = url + Url.format({ query });
        } else if ((method === 'POST' || method === 'PUT') && data) {
            headers.append('Content-type', 'application/json');
            body = JSON.stringify(data);
        }

        return getTokenAction()
            .then((token) => {
                headers.append('Authorization', `Bearer ${token}`);
                headers.append('Rockstat-uid', getRockstatUid());

                const fetchParams = {
                    headers,
                    method,
                };

                if (method !== 'GET') {
                    fetchParams.body = body;
                }

                if (requestId !== '') {
                    const oldController = requestControllers[requestId];

                    if (oldController !== undefined) {
                        oldController.abort();
                    }

                    const newController = new AbortController();

                    requestControllers[requestId] = newController;
                    fetchParams.signal = newController.signal;
                }

                if (withCaptcha) {
                    return captchaManager.getYandexCaptchaToken().then((smartCaptchaToken) => {
                        if (smartCaptchaToken) {
                            headers.append('yacaptcha-response', smartCaptchaToken);
                        }

                        return captchaManager.getCaptchaToken().then((googleCaptchaToken) => {
                            headers.append('g-recaptcha-response', googleCaptchaToken);

                            return fetch(fullUrl, fetchParams);
                        });
                    });
                }

                return fetch(fullUrl, fetchParams);
            })
            .then(parseBody)
            .catch((e) => {
                const isAbortError = e.name === 'AbortError';

                return isAbortError ? Promise.resolve : parseError(e);
            })
            .catch((error) => {
                if (
                    isUserAuth &&
                    (error.code === ERROR_TYPES.UNAUTHORIZED ||
                        (error.code === ERROR_TYPES.BAD_REQUEST && error.data.error === 'invalid_grant'))
                ) {
                    store.dispatch(logout());
                }

                const loginErrorCode = 40102;

                if (
                    !isUserAuth &&
                    error.code === ERROR_TYPES.UNAUTHORIZED &&
                    error.data.meta.error_code !== loginErrorCode
                ) {
                    cacheManager.removeItem(CLIENT_SESSION_CACHE_NAME);

                    return this._request(
                        method,
                        url,
                        params,
                        data,
                        isUserAuth,
                        requestId,
                        unwrappedParams,
                        withCaptcha
                    );
                }

                if (error.code === ERROR_TYPES.FORBIDDEN) {
                    forbiddenErrorHandler(error, url);
                }

                throw error;
            });
    },
};
