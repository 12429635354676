import cn from 'classnames';
import React from 'react';

import type { Passenger } from '../../types';
import { PassengerInfo } from '../PassengerInfo';
import styles from './Row.module.scss';

interface Props {
    className: string;
    passenger: Passenger;
    Action: React.ReactNode;
}

export function ModalRow({ className, passenger, Action }: Props) {
    return (
        <div className={cn(styles.root, className)}>
            <div className={styles.action}>{Action}</div>
            <PassengerInfo passenger={passenger} />
        </div>
    );
}
