import { useCallback } from 'react';

import { extractError } from 'checkin/shared/api';
import { createContextStore } from 'checkin/shared/lib/createContextStore';

import { getTicketsApi, getUserTicketsApi } from '../api';
import { mapDataToModel } from '../lib';
import type { Ticket } from '../types';

export const { useQuery, useData, useAction, withContext } = createContextStore<Ticket[]>([]);

export function useGetTickets() {
    const { setData, setIsLoading, setError } = useAction();

    function getTickets(identifier: string, lastName: string) {
        setIsLoading(true);

        return getTicketsApi(identifier, lastName)
            .then((response) => {
                setData(mapDataToModel(response));
                setIsLoading(false);
            })
            .catch((e) => {
                setData([]);
                setIsLoading(false);
                setError(extractError(e));
            });
    }

    return useCallback(getTickets, [setError, setIsLoading, setData]);
}

export function useGetUserTickets() {
    const { setData, setIsLoading, setError } = useAction();

    function getUserTickets() {
        setIsLoading(true);

        return getUserTicketsApi()
            .then((response) => {
                const data = mapDataToModel(response);
                setData(data);
                setIsLoading(false);

                return data;
            })
            .catch((e) => {
                const emptyData: Ticket[] = [];
                setData(emptyData);
                setIsLoading(false);
                setError(extractError(e));

                return emptyData;
            });
    }

    return useCallback(getUserTickets, [setError, setIsLoading, setData]);
}
