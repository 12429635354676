import cn from 'classnames';
import React from 'react';

import type { Passenger } from '../../types';
import styles from './PassengerInfo.module.scss';

interface Props {
    className?: string;
    passenger: Passenger;
}

export function PassengerInfo({ className = '', passenger }: Props) {
    return (
        <div className={cn(styles.root, className)}>
            <span className={styles.name}>{passenger.name}</span>
            <div className={styles.document}>
                Номер документа <div className={styles.dot} /> {passenger.documentNumber}
            </div>
        </div>
    );
}
