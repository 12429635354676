import { getDayAndMonthAndYear, getHoursAndMinutes } from 'checkin/shared/lib/dates';
import { getFormattedPassportNumber } from 'checkin/shared/lib/docs';

import type { Ticket, TicketResponse } from '../types';

export function mapDataToModel(tickets: TicketResponse[]): Ticket[] {
    if (!(tickets && tickets.length)) return [];

    return tickets.map((ticket) => ({
        orderUuid: ticket.order_uuid,
        rloc: ticket.rloc,
        segments: ticket.segments.map((segment) => ({
            segmentId: segment.segment_id,
            departureCityName: segment.departure_city_name,
            arrivalCityName: segment.arrival_city_name,
            departureAirportCode: segment.departure_airport_code,
            arrivalAirportCode: segment.arrival_airport_code,
            flightNumber: segment.flight_number,
            boardingCloseTime: segment.boarding_close_time,
            departureTime: segment.departure_time,
            arrivalTime: segment.arrival_time,
            ak: segment.ak,
            gate: segment.gate,
            serviceAvailability: {
                upgradeAvailable: segment.service_availability.upgrade_available,
                baggageAvailable: segment.service_availability.baggage_available,
                mealAvailable: segment.service_availability.meal_available,
                insuranceAvailable: segment.service_availability.insurance_available,
            },
            passengers: segment.passengers.map((passenger) => ({
                passengerId: passenger.passenger_id,
                parentId: passenger.parent_id,
                firstName: passenger.first_name,
                lastName: passenger.last_name,
                isJumpSeat: passenger.is_jump_seat,
                barcode: passenger.barcode,
                seat: passenger.seat,
                document: {
                    number: passenger.document.number,
                    type: passenger.document.type,
                },
                statusLevel: passenger.status_level,
                isChecked: false,
                name: `${passenger.last_name} ${passenger.first_name}`,
                documentNumber: getFormattedPassportNumber(passenger.document.type, passenger.document.number),
            })),
            date: getDayAndMonthAndYear(segment.departure_time),
            flight: `${segment.ak} ${segment.flight_number}`,
            direction: `${segment.departure_city_name} ${segment.departure_airport_code} – ${segment.arrival_city_name} ${segment.arrival_airport_code}`,
            duration: `${getHoursAndMinutes(segment.departure_time)} — ${getHoursAndMinutes(segment.arrival_time)}`,
        })),
    }));
}
